import { saveAsFile } from '@/utils/download';
import { ElMessage } from 'element-plus';
import { apiEgpmDownloadLogSave } from '@/request/egpmApi';

export default () => {

    function onDownload(row) {
        if (!row.fileUrl) {
            ElMessage.error('下载链接不存在');
            return;
        }
        const href = `${row.fileUrl}`;
        saveAsFile(href, row.taskName);
        ElMessage.success('已加入浏览器下载队列');
        // 记录下载日志
        apiEgpmDownloadLogSave({ id: row.id, taskId: row.taskId });
    }

    return { onDownload }
}